var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my_show_goods" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showGoods,
            width: "420px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showGoods = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            !_vm.id
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      background: "#fff",
                    },
                  },
                  [_vm._t("default")],
                  2
                )
              : _c("iframe", {
                  attrs: { src: _vm.url, width: "100%", height: "100%" },
                }),
            _c("img", {
              staticClass: "clear-icon",
              attrs: {
                src: "https://nb-img.hzanchu.com/h5/nbh_admin/img/20231219122414.png",
                alt: "",
              },
              on: { click: _vm.handleClose },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }