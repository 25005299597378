// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.my_show_goods .content {\n  width: 100%;\n  height: 100%;\n  background: url(\"https://img.hzanchu.com/acimg/d98838a65dd0bcb4c9f3182cef3d9d46.png\");\n  background-size: 100% 100%;\n  padding: 60px 30px 30px;\n  text-align: center;\n}\n.my_show_goods .content iframe {\n  border-radius: 10px;\n}\n.my_show_goods .content .clear-icon {\n  margin-top: 40px;\n  width: 40px;\n}\n.my_show_goods .el-dialog {\n  height: 817px;\n  margin: 0 auto 0 !important;\n  border-radius: 60px;\n}\n.my_show_goods .el-dialog .el-dialog__header {\n  display: none;\n}\n.my_show_goods .el-dialog .el-dialog__body {\n  padding: 0;\n  height: 100%;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
