<template>
  <div class="my_show_goods">
    <el-dialog
      title=""
      :visible.sync="showGoods"
      width="420px"
      :before-close="handleClose"
    >
      <div class="content">
        <div
          v-if="!id"
          style="width: 100%; height: 100%; background: #fff;"
        >
          <slot></slot>
        </div>
        <iframe
          v-else
          :src="url"
          width="100%"
          height="100%"
        ></iframe>
        <img
          class="clear-icon"
          src="https://nb-img.hzanchu.com/h5/nbh_admin/img/20231219122414.png"
          alt=""
          @click="handleClose"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { projectConfigDetail } from '@/utils/projectConfig';

const { h5Url, code } = projectConfigDetail;

export default {
  props: ['showGoods', 'id', 'goodsType'],
  data() {
    return {
      url: '',
    };
  },
  mounted() {
    if (Number(this.goodsType) === 1) {
      this.url = `${h5Url}/goodsdetail?from_type=preview&id=${this.id}&entranType=3&projectCode=${code}`;
    } else if (Number(this.goodsType) === 2) {
      this.url = `${h5Url}/homestay/detail?from_type=preview&goods_id=${this.id}&entranType=3&projectCode=${code}`;
    } else if (Number(this.goodsType) === 3) {
      this.url = `${h5Url}/landmark/detail?from_type=preview&goods_id=${this.id}&entranType=3&projectCode=${code}`;
    } else if (Number(this.goodsType) === 4) {
      this.url = `${h5Url}/travel/detail?from_type=preview&goods_id=${this.id}&entranType=3&projectCode=${code}`;
    } else if (this.goodsType === 'qa') {
      this.url = `${h5Url}/supplierQA?id=${this.id}`;
    } else {
      this.url = `${h5Url}/goodsdetail?from_type=preview&id=${this.id}&entranType=3&projectCode=${code}`;
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeShowGoods');
    },
  },
};
</script>

<style lang="scss">
.my_show_goods {
  .content {
    width: 100%;
    height: 100%;
    background: url('https://img.hzanchu.com/acimg/d98838a65dd0bcb4c9f3182cef3d9d46.png');
    background-size: 100% 100%;
    padding: 60px 30px 30px;
    text-align: center;
    iframe {
      border-radius: 10px;
    }
    .clear-icon {
      margin-top: 40px;
      width: 40px;
    }
  }
  .el-dialog {
      height: 817px;
      margin: 0 auto 0 !important;
      // overflow-y: auto;
      border-radius: 60px;
      .el-dialog__header {
          display: none;
      }
      .el-dialog__body {
          padding: 0;
          height: 100%;
      }
  }
}
</style>
